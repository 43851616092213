import React from "react";
import styled from "@emotion/styled";
import { withTheme } from "@emotion/react";
import Chart from "react-apexcharts";
import moment from "moment";

import { CardContent, Card as MuiCard, Typography } from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Spacer = styled.div(spacing);

const ChartWrapper = styled.div`
  height: 350px;
  width: 100%;
`;
const TheamChartConversation = ({ theme, RowData, reportName }) => {
  var FieldName = !!RowData ? Object.keys(RowData[0])[1] : "";
  var mainData = [];
  var mainHeader = [];
  // console.log("FieldName == ", FieldName);
  switch (FieldName) {
    case "count":
      !!RowData &&
        RowData.map(
          (item, i) => mainData.push(item.count) && mainHeader.push(item.date)
        );
      break;
    case "NoOfCount":
      !!RowData &&
        RowData.map(
          (item, i) =>
            mainData.push(item.NoOfCount) && mainHeader.push(item.Date)
        );
      break;
    case "ResolutionCount":
      !!RowData &&
        RowData.map(
          (item, i) =>
            mainData.push(item.ResolutionCount) && mainHeader.push(item.Date)
        );
      break;
    case "resolutionTime":
      !!RowData &&
        RowData.map(
          (item, i) =>
            //mainData.push(item.FirstResponseTime) && mainHeader.push(item.Date)
            mainData.push(
              moment(item.resolutionTime, "HH:mm:ss").diff(
                moment().startOf("day"),
                "seconds"
              )
            ) && mainHeader.push(item.Date)
        );
      break;
    case "timecount":
      !!RowData &&
        RowData.map(
          (item, i) =>
            mainData.push(
              moment(item.timecount, "HH:mm:ss").diff(
                moment().startOf("day"),
                "seconds"
              )
            ) && mainHeader.push(item.date)
        );
      break;
    case "FirstResponseTime":
      !!RowData &&
        RowData.map(
          (item, i) =>
            //mainData.push(item.FirstResponseTime) && mainHeader.push(item.Date)
            mainData.push(
              moment(item.FirstResponseTime, "HH:mm:ss").diff(
                moment().startOf("day"),
                "seconds"
              )
            ) && mainHeader.push(item.Date)
        );
      break;
    default:
      break;
  }

  var SecondsOrCount = 0;
  SecondsOrCount =
    (!!FieldName && FieldName === "FirstResponseTime") ||
    FieldName === "resolutionTime" ||
    FieldName === "timecount"
      ? 1
      : 0;

  const data = [
    {
      name:
        parseInt(SecondsOrCount) === 1 ? "Per Day Seconds" : "Per Day Counts",
      type: "column",
      data: mainData,
    },
  ];

  const options = {
    chart: {
      stacked: false,
    },
    stroke: {
      width: [0, 2, 5],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "50%",
      },
    },
    fill: {
      opacity: [0.85, 0.25, 1],
      gradient: {
        inverseColors: false,
        shade: "light",
        type: "vertical",
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100],
      },
    },
    labels: mainHeader,
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
      //   title: {
      //     text: "Counts 555",
      //   },
    },
    yaxis: {
      title: {
        text: parseInt(SecondsOrCount) === 1 ? "Seconds" : "Counts",
      },
      min: 0,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          if (typeof y !== "undefined") {
            return parseInt(SecondsOrCount) === 1
              ? y.toFixed(0) + " Seconds"
              : y.toFixed(0) + " Counts";
          }
          return y;
        },
      },
    },
    colors: [theme.palette.primary.light],
  };

  return (
    <Card mb={1}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {!!reportName && reportName}
        </Typography>
        {/* <Typography variant="body2" gutterBottom>
          A Mixed Chart is a visualization that allows the combination of two or
          more distinct graphs.
        </Typography> */}

        <Spacer mb={6} />

        <ChartWrapper>
          <Chart options={options} series={data} type="line" height="350" />
        </ChartWrapper>
      </CardContent>
    </Card>
  );
};

export default withTheme(TheamChartConversation);
