import React, { useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { basePathConfig } from "../../../config";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Helmet } from "react-helmet-async";
import { Grid } from "@mui/material";
import LeftChatPannel from "./leftPannel/LeftChatPannel";
import RightChatPannel from "./rightPannel/RightChatPannel";
import { useDispatch } from "react-redux";
import {
  fun_ChatFromoneSpoc,
  // fun_TodaysChat,
  fun_LoginLogout,
  fun_Contact_Upate,
  fun_TodaysChat,
} from "../../../redux/slices/socketsSlice";
import { isValidToken } from "../../../utils/jwt";
import {
  fetchLogOut,
  fetchLoginLogOut_Socket,
  logout,
} from "../../../redux/slices/login";
import { useNavigate } from "react-router-dom";
import useTokenExpirationHandler from "../../../utils/useTokenExpirationHandler";
const TopSpacing = styled("div")(spacing);

const ChatPannel = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = window.localStorage.getItem("accessToken");
  var socket = useRef(null);
  // let url = window.location.href;

  useTokenExpirationHandler();
  React.useEffect(() => {
    !isValidToken(accessToken) && dispatch(fetchLogOut());
    !isValidToken(accessToken) && dispatch(fetchLoginLogOut_Socket());
    !isValidToken(accessToken) && dispatch(logout());
    !isValidToken(accessToken) && navigate("/");
  }, [accessToken, dispatch, navigate]);

  useEffect(() => {
    socket.current = io(basePathConfig.socketURL, {
      reconnectionDelayMax: 10000,
    });
    socket.current.on("connect", () => {
      console.info(
        `Successfully connected to socket ${basePathConfig.socketURL}`
      );
    });

    const accountID = window.localStorage.getItem("atua");
    socket.current.emit("joinRoom", accountID);

    socket.current.on("chatFromoneSpoc", (data) => {
      //console.log("chatFromoneSpoc 1111  == ", JSON.stringify(data));
      if (!isValidToken(accessToken)) {
        dispatch(fetchLogOut());
        dispatch(fetchLoginLogOut_Socket());
        dispatch(logout());
        navigate("/");
      }
      dispatch(fun_ChatFromoneSpoc(data));
    });

    socket.current.on("joinRoomTest", () => {
      socket.current.emit("joinRoom", accountID);
    });

    socket.current.on("todaysChat", (data) => {
      //console.log("todaysChat 1111  == ", JSON.stringify(data));
      if (!isValidToken(accessToken)) {
        dispatch(fetchLogOut());
        dispatch(fetchLoginLogOut_Socket());
        dispatch(logout());
        navigate("/");
      }
      dispatch(fun_TodaysChat(data));
    });

    socket.current.on("loginlogoutstatus", (data) => {
      if (!isValidToken(accessToken)) {
        dispatch(fetchLogOut());
        dispatch(fetchLoginLogOut_Socket());
        dispatch(logout());
        navigate("/");
      }
      dispatch(fun_LoginLogout(data));
    });

    socket.current.on("contact_update", (data) => {
      if (!isValidToken(accessToken)) {
        dispatch(fetchLogOut());
        dispatch(fetchLoginLogOut_Socket());
        dispatch(logout());
        navigate("/");
      }
      dispatch(fun_Contact_Upate(data));
    });

    return () => {
      socket.current.disconnect();
    };
  }, [accessToken, dispatch, navigate, socket]);
  return (
    <>
      <Helmet title="Chats" />
      <Grid container>
        <Grid item xs={12} lg={2} className="conversations-counts">
          <TopSpacing gutterBottom className="fullHeightLeft">
            <LeftChatPannel socket={socket} />
          </TopSpacing>
        </Grid>
        <Grid item xs={12} lg={10}>
          <TopSpacing gutterBottom className="fullHeightRight">
            <RightChatPannel socket={socket} />
          </TopSpacing>
        </Grid>
      </Grid>
    </>
  );
};

export default ChatPannel;
