import React from "react";
import styled from "@emotion/styled";

import {
  Alert as MuiAlert,
  Grid,
  IconButton,
  InputBase,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  // FormControlLabel,
  // Switch,
  Button,
} from "@mui/material";

import { spacing } from "@mui/system";
import DownloadIcon from "@mui/icons-material/Download";
import { CSVLink } from "react-csv";
import AddchartIcon from "@mui/icons-material/Addchart";
import ChartOverview from "./charts/ChartOverview";
import SearchIcon from "@mui/icons-material/Search";
const Paper = styled(MuiPaper)(spacing);
const timeOut = (time) => new Promise((res) => setTimeout(res, time));
const Alert = styled(MuiAlert)(spacing);
const Spacer = styled.div`
  flex: 1 1 50%;
`;
const Search = styled.div`
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #e7f2ff;
`;

// Helper Datetime function here
function convertTo12HourFormat(time24) {
  const [hours, minutes, seconds] = time24.split(":");
  const ampm = hours >= 12 ? "PM" : "AM";
  const hours12 = hours % 12 || 12; // Convert to 12-hour format and handle midnight
  return `${hours12}:${minutes}:${seconds} ${ampm}`;
}
function createData(
  Date,
  Role,
  Name,
  FirstSignIn,
  LastSignOut,
  SessionCount,
  SessionTime
) {
  return {
    Date,
    Role,
    Name,
    FirstSignIn,
    LastSignOut,
    SessionCount,
    SessionTime,
  };
}

var rows = [];
var rowsData = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}
//  Date, Role, Name, FirstSignIn, LastSignOut, SessionCount, SessionTime
const headCells = [
  {
    id: "srno",
    numeric: false,
    disablePadding: false,
    label: "Sr.No.",
  },
  {
    id: "Date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  { id: "Role", numeric: false, disablePadding: false, label: "Role" },
  { id: "Name", numeric: false, disablePadding: false, label: "Agent Name" },
  {
    id: "FirstSignIn",
    numeric: false,
    disablePadding: false,
    label: "First Sign-In",
  },
  {
    id: "LastSignOut",
    numeric: false,
    disablePadding: false,
    label: "Last Sign-Out",
  },
  {
    id: "SessionCount",
    numeric: false,
    disablePadding: false,
    label: "Session Count",
  },
  {
    id: "SessionTime",
    numeric: false,
    disablePadding: false,
    label: "Session Time",
  },
];

var HeaderName = [];
headCells.map(
  (item) => !!item.id && item.id !== "srno" && HeaderName.push(item.id)
);

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={"checkbox"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const [showAlert, setShowAlert] = React.useState(false);
  //const { handleChangeDense, dense } = props;
  var [timeStamp, setTimeStamp] = React.useState(Number(new Date()));
  // show chart Conversation
  const [openAdd, setOpenAdd] = React.useState({
    Open: false,
    data: "",
    report: "",
    name: "",
  });
  const handleAddClickOpen = async () => {
    if (!!props.rows && props.rows.length > 0) {
      setOpenAdd({
        Open: true,
        data: props.ChartRows,
        report: "Login Logout Overview",
        name: "",
      });
    } else {
      setShowAlert(true);
      await timeOut(1500);
      setShowAlert(false);
    }
  };

  const handleAddClose = () => {
    setOpenAdd({
      Open: false,
      data: "",
      report: "",
      name: "",
    });
  };

  return (
    <>
      <Toolbar>
        <div>
          <Typography variant="h6" id="tableTitle" color="primary">
            Overview
          </Typography>
        </div>
        <Spacer />
        <div>
          <Button
            variant="contained"
            color="success"
            style={{ marginRight: "20px" }}
            onClick={() => {
              handleAddClickOpen();
            }}
          >
            <AddchartIcon />
          </Button>
          <Button variant="contained" color="primary" mr={10}>
            <DownloadIcon />
            <CSVLink
              data={props.rows}
              headers={HeaderName}
              className="whiteColor"
              onClick={() => {
                setTimeStamp(Number(new Date()));
              }}
              filename={`AgentLoginLogoutOverviewReports${timeStamp}.csv`}
            >
              Download Report
            </CSVLink>
          </Button>
          {/* <FormControlLabel
            control={<Switch checked={dense} onChange={handleChangeDense} />}
            label="Dense padding"
            ml={10}
          /> */}
        </div>
        <ChartOverview
          openAdd={openAdd}
          handleAddClose={handleAddClose}
          handleAddClickOpen={handleAddClickOpen}
        />
      </Toolbar>
      {!!showAlert && (
        <Alert severity="error" my={3}>
          No Data Found
        </Alert>
      )}
    </>
  );
};

function EnhancedTable({ overview, chartoverview }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  var [overviewData, setOverviewData] = React.useState([]);
  const [search, setSearch] = React.useState("");
  var [chartoverviewData, setChartoverviewData] = React.useState([]);
  React.useEffect(() => {
    !!overview ? setOverviewData(overview) : setOverviewData([]);
    !!chartoverview
      ? setChartoverviewData(chartoverview)
      : setChartoverviewData([]);
    setDense(true);
  }, [chartoverview, overview]);
  //Date, Role, Name, FirstSignIn, LastSignOut, SessionCount, SessionTime
  rowsData = !!overviewData
    ? overviewData.map((item, i) =>
        createData(
          item.date,
          item.agentName.role === 0 ? "Admin" : "Agent",
          item.agentName.displayname,
          item.firstSignin,
          item.lastSignout,
          item.sessionCount,
          item.sessiontime
        )
      )
    : [];
  rows =
    search === ""
      ? rowsData
      : rowsData?.filter((i) =>
          i?.Name.toLowerCase().includes(search.toLowerCase())
        );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          handleChangeDense={handleChangeDense}
          dense={dense}
          rows={!!rows ? rows : []}
          ChartRows={!!chartoverviewData ? chartoverviewData : []}
        />
        <Search>
          <IconButton type="button" sx={{ p: "4px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search Agent Name"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Search>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.date);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  //Date, Role, Name, FirstSignIn, LastSignOut, SessionCount, SessionTime
                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.date)}
                      key={row.date}
                      selected={isItemSelected}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="checkbox"
                      >
                        {/* {index + 1} */}
                        {page === 0
                          ? index + 1
                          : index + 1 + rowsPerPage * page}
                      </TableCell>
                      <TableCell align="left">{row.Date} </TableCell>
                      <TableCell align="left">{row.Role}</TableCell>
                      <TableCell align="left">{row.Name}</TableCell>
                      <TableCell align="left">
                        {row.FirstSignIn === "00:00:00"
                          ? row.FirstSignIn
                          : convertTo12HourFormat(row.FirstSignIn)}
                      </TableCell>
                      <TableCell align="left">
                        {row.LastSignOut === "00:00:00"
                          ? row.LastSignOut
                          : convertTo12HourFormat(row.LastSignOut)}
                      </TableCell>
                      <TableCell align="left">{row.SessionCount}</TableCell>
                      <TableCell align="left">{row.SessionTime}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function OverviewTab({ overview, chartoverview }) {
  return (
    <React.Fragment>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable overview={overview} chartoverview={chartoverview} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default OverviewTab;
